<template>
  <span class="clearfix mb-0">
    <span class="float-md-left d-inline-block d-md-inline-block align-middle">
      <img
        :src="require('@/assets/images/logo/logo-red.png')"
        style="height: 26px"
        class="my-1"
      />
      © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https:glogc.com" target="_blank"
        >GOBUILDERS NETSOFT SDN BHD (1232479-T).
      </b-link>
      <span class="d-none d-sm-inline-block">ALL RIGHTS RESERVED.</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <img
        :src="require('@/assets/images/logo/visa.png')"
        style="height: 52px"
      />
    </span>
  </span>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
